body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  --page-max-width: 1400px;
}
a {
  display: inherit;
  text-decoration: none;
  color: inherit;
}
.slider {
  color: #20b2aa;
}
p {
  white-space: break-spaces;
}
